import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Star, Users, Shield, ChevronLeft, ChevronRight, Menu, X, CheckCircle, Phone, Mail, MapPin, Facebook, Twitter, Instagram } from 'lucide-react';

const reviews = [
  { name: "Ahmet Y.", comment: "Çok hızlı ve kolay bir şekilde trafik sigortamı yaptırdım. Teşekkürler!" },
  { name: "Ayşe K.", comment: "Fiyatlar gerçekten uygun. Müşteri hizmetleri de çok yardımcı oldu." },
  { name: "Mehmet S.", comment: "Tüm süreci online halledebildim, çok pratik bir sistem." },
];

export default function TrafikSigortasiFiyat() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [formData, setFormData] = useState({
    tcno: '',
    plaka: '',
    telefon: ''
  });
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/trafiksigortasi.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString()
      });
      const data = await response.json();
      if (data.success) {
        setMessage('Teşekkürler! Teklif isteğiniz başarıyla alındı. En uygun fiyatlarla kısa süre içinde size ulaşacağız.');
        setIsSuccess(true);
        setIsModalOpen(true);
      
        // Google Ads dönüşüm izleme kodu burada çalışır
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16665757756/RWydCNq18MgZELyQ7Yo-',
          'value': 1.0,
          'currency': 'TRY'
        });
      
        setFormData({ tcno: '', plaka: '', telefon: '' });
      } else {
        setMessage(data.message || 'Bir hata oluştu. Lütfen tekrar deneyiniz.');
        setIsSuccess(false);
        setIsModalOpen(true);
      }
    } catch (error) {
      setMessage('Bir hata oluştu. Lütfen tekrar deneyiniz.');
      setIsSuccess(false);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gray-50 text-gray-800 min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-white shadow-md sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <nav className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <a href="https://www.sigortasatinal.com" aria-label="Anasayfa" className="flex-shrink-0">
                <img
                  src={process.env.PUBLIC_URL + '/logos/centalya.webp'}
                  alt="Şirket Logosu"
                  className="h-12 mr-6"
                />
              </a>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex space-x-8">
              <Link to="/urunler" className="text-gray-700 hover:text-red-600 font-medium transition duration-200">Ürünler</Link>
              <Link to="/hakkimizda" className="text-gray-700 hover:text-red-600 font-medium transition duration-200">Hakkımızda</Link>
              <Link to="/iletisim" className="text-gray-700 hover:text-red-600 font-medium transition duration-200">İletişim</Link>
            </div>

            {/* Mobile Menu Button */}
            <button 
              className="md:hidden text-gray-700 hover:text-red-600 focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label={isMenuOpen ? "Menüyü Kapat" : "Menüyü Aç"}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </nav>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden bg-white border-t border-gray-200">
            <div className="container mx-auto px-4 py-2 space-y-2">
              <Link to="/urunler" className="block py-3 px-4 text-gray-700 hover:bg-red-50 hover:text-red-600 rounded-lg transition duration-200">Ürünler</Link>
              <Link to="/hakkimizda" className="block py-3 px-4 text-gray-700 hover:bg-red-50 hover:text-red-600 rounded-lg transition duration-200">Hakkımızda</Link>
              <Link to="/iletisim" className="block py-3 px-4 text-gray-700 hover:bg-red-50 hover:text-red-600 rounded-lg transition duration-200">İletişim</Link>
            </div>
          </div>
        )}
      </header>

      {/* Main Content */}
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8 max-w-6xl">
          {/* Hero Section with Greeting */}
          <div className="bg-gradient-to-r from-red-500 to-red-600 rounded-xl p-5 mb-6 shadow-md">
            <div className="flex flex-col md:flex-row items-center gap-4">
              <div className="bg-white p-2 rounded-full shadow-md">
                <img src="/logos/personel.jpg" alt="Personel" className="w-20 h-20 rounded-full object-cover" />
              </div>
              <div className="text-white text-center md:text-left">
                <h1 className="text-2xl font-bold mb-1">Hoşgeldiniz!</h1>
                <p className="text-base">Ben Sigorta Uzmanı Elif. Bizden teklif almak için aşağıdaki bilgileri kolayca girebilirsiniz.</p>
              </div>
            </div>
          </div>

          <div className="max-w-2xl mx-auto">
            {/* Form Section */}
            <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
              <div className="bg-red-600 py-4 px-6">
                <h2 className="text-xl font-bold text-white">Trafik Sigortası Teklif Formu</h2>
              </div>
              <form onSubmit={handleSubmit} className="p-6 space-y-4">
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="tcno">
                    T.C. Kimlik Numarası
                  </label>
                  <input 
                    className="w-full bg-gray-50 border border-gray-300 rounded-lg py-3 px-4 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition duration-200" 
                    id="tcno" 
                    type="tel" 
                    placeholder="T.C. Kimlik Numarası" 
                    value={formData.tcno}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="plaka">
                    Plaka
                  </label>
                  <input 
                    className="w-full bg-gray-50 border border-gray-300 rounded-lg py-3 px-4 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition duration-200" 
                    id="plaka" 
                    type="text" 
                    placeholder="Plaka" 
                    value={formData.plaka}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="telefon">
                    Telefon Numarası
                  </label>
                  <input 
                    className="w-full bg-gray-50 border border-gray-300 rounded-lg py-3 px-4 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition duration-200" 
                    id="telefon" 
                    type="tel" 
                    placeholder="Telefon Numarası" 
                    value={formData.telefon}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between gap-4 pt-2">
                  <button 
                    className="w-full md:w-auto bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-8 rounded-lg shadow-md transition duration-200 transform hover:scale-105 focus:outline-none" 
                    type="submit"
                  >
                    Teklif Al
                  </button>
                  <p className="text-sm text-gray-500 text-center md:text-left">
                    Bu formu göndererek <Link to="/kullanim-kosullari" className="text-red-600 hover:text-red-800 underline">site kullanım koşullarını</Link> kabul etmiş sayılırsınız.
                  </p>
                </div>
              </form>
            </div>
          </div>

          {/* Customer Reviews */}
          <div className="mt-12">
            <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">Müşteri Yorumları</h2>
            <div className="grid md:grid-cols-3 gap-6">
              {reviews.map((review, index) => (
                <div key={index} className="bg-white p-6 rounded-2xl shadow-lg border-t-4 border-red-500 flex flex-col">
                  <div className="flex mb-4">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="text-yellow-400" size={20} fill="#FBBF24" />
                    ))}
                  </div>
                  <p className="text-gray-700 mb-4 flex-grow">{review.comment}</p>
                  <p className="font-bold text-gray-800">{review.name}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Benefits Section */}
          <div className="mt-16">
            <h2 className="text-2xl font-bold mb-8 text-gray-800 text-center">Neden Bizi Tercih Etmelisiniz?</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-2xl shadow-lg text-center">
                <div className="bg-red-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                  <Shield className="text-red-600" size={32} />
                </div>
                <h3 className="text-xl font-bold mb-2 text-gray-800">Güvenilir Hizmet</h3>
                <p className="text-gray-600">En güvenilir sigorta şirketleriyle çalışarak size en iyi hizmeti sunuyoruz.</p>
              </div>
              <div className="bg-white p-6 rounded-2xl shadow-lg text-center">
                <div className="bg-red-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                  <Users className="text-red-600" size={32} />
                </div>
                <h3 className="text-xl font-bold mb-2 text-gray-800">Uzman Kadro</h3>
                <p className="text-gray-600">Deneyimli sigorta uzmanlarımız her adımda size yardımcı olmak için hazır.</p>
              </div>
              <div className="bg-white p-6 rounded-2xl shadow-lg text-center">
                <div className="bg-red-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                  <CheckCircle className="text-red-600" size={32} />
                </div>
                <h3 className="text-xl font-bold mb-2 text-gray-800">Hızlı İşlem</h3>
                <p className="text-gray-600">Hızlı ve kolay süreçlerle vakit kaybı yaşamadan sigortanızı yaptırın.</p>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Success Modal */}
      {isModalOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity z-40" onClick={closeModal}></div>
          <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-2xl shadow-xl p-6 max-w-md w-full text-center relative animate-fadeIn">
              <CheckCircle className={`mx-auto mb-4 ${isSuccess ? 'text-green-500' : 'text-red-500'}`} size={64} />
              <h3 className="text-xl font-bold mb-2">{isSuccess ? 'Başarılı!' : 'Uyarı'}</h3>
              <p className="text-gray-700 mb-6">{message}</p>
              <button
                onClick={closeModal}
                className="bg-red-600 hover:bg-red-700 text-white py-3 px-6 rounded-lg font-medium transition duration-200"
              >
                Kapat
              </button>
            </div>
          </div>
        </>
      )}

      {/* Footer */}
      <footer className="bg-gray-900 text-white pt-12 pb-6 mt-16">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8 mb-8">
            <div>
              <h3 className="text-xl font-bold mb-4 border-b border-red-500 pb-2 inline-block">Centalya Sigorta</h3>
              <p className="text-gray-300 mb-6">Geleceğinizi güvence altına alıyoruz.</p>
              <div className="flex space-x-4">
                <a href="#" className="bg-red-600 hover:bg-red-700 w-10 h-10 rounded-full flex items-center justify-center transition duration-200">
                  <Facebook size={20} />
                </a>
                <a href="#" className="bg-red-600 hover:bg-red-700 w-10 h-10 rounded-full flex items-center justify-center transition duration-200">
                  <Twitter size={20} />
                </a>
                <a href="#" className="bg-red-600 hover:bg-red-700 w-10 h-10 rounded-full flex items-center justify-center transition duration-200">
                  <Instagram size={20} />
                </a>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4 border-b border-red-500 pb-2 inline-block">İletişim</h3>
              <ul className="text-gray-300 space-y-3">
                <li className="flex items-start">
                  <MapPin className="mr-2 text-red-500 flex-shrink-0 mt-1" size={18} />
                  <span>Kanal Mah. Yasemin Cad. Nil Apt. no: 7/8 Kepez Antalya</span>
                </li>
                <li className="flex items-center">
                  <Phone className="mr-2 text-red-500 flex-shrink-0" size={18} />
                  <span>(0242) 331 03 00 - 0501 076 07 07</span>
                </li>
                <li className="flex items-center">
                  <Mail className="mr-2 text-red-500 flex-shrink-0" size={18} />
                  <span>info@centalyasigorta.com.tr</span>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4 border-b border-red-500 pb-2 inline-block">Hızlı Bağlantılar</h3>
              <ul className="text-gray-300 space-y-2">
                <li><Link to="/urunler" className="hover:text-red-500 transition duration-200">Ürünlerimiz</Link></li>
                <li><Link to="/hakkimizda" className="hover:text-red-500 transition duration-200">Hakkımızda</Link></li>
                <li><Link to="/iletisim" className="hover:text-red-500 transition duration-200">İletişim</Link></li>
                <li><Link to="/kullanim-kosullari" className="hover:text-red-500 transition duration-200">Kullanım Koşulları</Link></li>
                <li><Link to="/gizlilik-politikasi" className="hover:text-red-500 transition duration-200">Gizlilik Politikası</Link></li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 pt-6 text-center text-gray-400 text-sm">
            <p>&copy; {new Date().getFullYear()} Centalya Sigorta Aracılık Hiz. Ltd. Şti. Tüm Hakları Saklıdır.</p>
          </div>
        </div>
      </footer>

      {/* Add custom CSS */}
      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out forwards;
        }
      `}</style>
    </div>
  );
}