import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Bell, BarChart, Settings, Home, LogOut, Menu, Users, X, RefreshCw, Search, AlertTriangle } from 'lucide-react';

const AdminPanel = () => {
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [lastRefreshTime, setLastRefreshTime] = useState(new Date());
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState(30); // saniye cinsinden
  const refreshTimerRef = useRef(null);

  // Mutlak URL yolunu belirleyelim (gerekirse değiştirin)
  const API_BASE_URL = ''; // Boş bırakırsak göreceli yol kullanılır

  const fetchOffers = useCallback(async (page = currentPage, showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }
    
    try {
      // API yolunu göreceli veya mutlak olarak oluştur
      const apiUrl = `${API_BASE_URL}/get-all-offers.php?page=${page}&t=${new Date().getTime()}`; // Önbelleği engellemek için zaman damgası
      console.log('Veri çekiliyor:', apiUrl);
      
      const response = await fetch(apiUrl);
      const data = await response.json();
      
      console.log('API Yanıtı:', data);
      
      if (data.success) {
        setOffers(data.data);
        setTotalPages(Math.ceil(data.totalCount / data.perPage));
        setError(null);
        setLastRefreshTime(new Date());
      } else {
        console.error('API error:', data.error);
        setError(data.error || 'Teklifler alınırken bir hata oluştu.');
        if (offers.length === 0) {
          setOffers([]);
        }
      }
    } catch (error) {
      console.error('Teklifler alınırken hata oluştu:', error);
      setError('Teklifler alınırken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      if (offers.length === 0) {
        setOffers([]);
      }
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  }, [currentPage, API_BASE_URL, offers.length]);

  // Otomatik yenileme için useEffect
  useEffect(() => {
    // Önceki zamanlayıcıyı temizle
    if (refreshTimerRef.current) {
      clearInterval(refreshTimerRef.current);
    }
    
    // Otomatik yenileme aktifse, yeni bir zamanlayıcı başlat
    if (autoRefresh) {
      refreshTimerRef.current = setInterval(() => {
        console.log('Otomatik yenileme gerçekleştiriliyor...');
        fetchOffers(currentPage, false); // Yüklenme göstergesini gösterme
      }, refreshInterval * 1000);
    }
    
    // Component unmount olduğunda zamanlayıcıyı temizle
    return () => {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }
    };
  }, [autoRefresh, refreshInterval, fetchOffers, currentPage]);

  // Sayfa yüklendiğinde verileri çek
  useEffect(() => {
    fetchOffers(currentPage);
  }, [currentPage, fetchOffers]);

  const handleStatusChange = async (id, newStatus, offerType) => {
    try {
      const payload = { id, status: newStatus, type: offerType };
      console.log('Durum güncelleme isteği gönderiliyor:', payload);
      
      const response = await fetch(`${API_BASE_URL}/update-offer-status.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      
      console.log('Durum güncelleme yanıtı:', result);
      
      if (result.success) {
        // Yerel state'i güncelle
        setOffers(prevOffers => 
          prevOffers.map(offer => 
            (offer.id === id && offer.type === offerType) 
              ? { ...offer, status: newStatus } 
              : offer
          )
        );
        setError(null);
      } else {
        console.error('Durum güncellenirken hata oluştu:', result.message);
        setError(`Durum güncellenirken hata oluştu: ${result.message}`);
      }
    } catch (error) {
      console.error('Durum güncellenirken hata oluştu:', error);
      setError(`Durum güncellenirken hata oluştu: ${error.message}`);
    }
  };

  const getOfferTypeSpecificData = (offer) => {
    switch (offer.type) {
      case 'trafik':
        return {
          plakaOrDate: offer.plaka,
          ruhsatOrAile: offer.ruhsat_no,
          displayType: 'Trafik Sigortası'
        };
      case 'saglik':
        return {
          plakaOrDate: offer.plaka, // dogum_tarihi olarak gelen değer
          ruhsatOrAile: offer.ruhsat_no === '1' ? 'Evet' : 'Hayır', // aile_police olarak gelen değer
          displayType: 'Sağlık Sigortası'
        };
      case 'kasko':
        return {
          plakaOrDate: offer.plaka,
          ruhsatOrAile: offer.ruhsat_no,
          displayType: 'Kasko'
        };
      default:
        return {
          plakaOrDate: '-',
          ruhsatOrAile: '-',
          displayType: offer.type
        };
    }
  };

  // Filtreleme işlevi
  const filteredOffers = offers.filter(offer => {
    // Durum filtreleme
    if (activeFilter !== 'all' && offer.status !== activeFilter) {
      return false;
    }
    
    // Arama filtresi
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      return (
        (offer.tc_kimlik?.toLowerCase().includes(searchLower)) ||
        (offer.plaka?.toLowerCase().includes(searchLower)) ||
        (offer.telefon?.toLowerCase().includes(searchLower)) ||
        (offer.type?.toLowerCase().includes(searchLower))
      );
    }
    
    return true;
  });

  // İstatistikler
  const stats = {
    total: offers.length,
    pending: offers.filter(offer => offer.status === 'pending').length,
    processed: offers.filter(offer => offer.status === 'processed').length,
  };

  // Son yenileme zamanını formatla
  const formattedLastRefreshTime = lastRefreshTime.toLocaleTimeString('tr-TR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  if (loading && offers.length === 0) {
    return (
      <div className="flex h-screen bg-gray-100">
        <div className="flex flex-col flex-1 overflow-hidden">
          <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              <span className="ml-3 text-lg font-medium text-gray-700">Yükleniyor...</span>
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar (Mobil) */}
      <div className={`${sidebarOpen ? 'block' : 'hidden'} fixed inset-0 z-40 md:hidden`}>
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" onClick={() => setSidebarOpen(false)}></div>
        
        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
          <div className="absolute top-0 right-0 -mr-12 pt-2">
            <button
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() => setSidebarOpen(false)}
            >
              <span className="sr-only">Kapat</span>
              <X className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          
          <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div className="flex-shrink-0 flex items-center px-4">
              <span className="text-2xl font-semibold text-blue-600">Sigorta Panel</span>
            </div>
            <nav className="mt-5 px-2 space-y-1">
              <a href="#" className="bg-blue-100 text-blue-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                <Home className="mr-4 h-6 w-6 text-blue-500" aria-hidden="true" />
                Gösterge Paneli
              </a>
              <a href="#" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                <Users className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                Kullanıcılar
              </a>
              <a href="#" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                <BarChart className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                Raporlar
              </a>
              <a href="#" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                <Settings className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                Ayarlar
              </a>
            </nav>
          </div>
          
          <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
            <a href="#" className="flex-shrink-0 group block">
              <div className="flex items-center">
                <div>
                  <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center text-gray-700 font-bold">
                    A
                  </div>
                </div>
                <div className="ml-3">
                  <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">Admin Kullanıcı</p>
                  <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Çıkış Yap</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* Sidebar (Masaüstü) */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1 bg-white border-r border-gray-200">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <span className="text-2xl font-semibold text-blue-600">Sigorta Panel</span>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                <a href="#" className="bg-blue-100 text-blue-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <Home className="mr-3 h-6 w-6 text-blue-500" aria-hidden="true" />
                  Gösterge Paneli
                </a>
                <a href="#" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <Users className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Kullanıcılar
                </a>
                <a href="#" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <BarChart className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Raporlar
                </a>
                <a href="#" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <Settings className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Ayarlar
                </a>
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <div className="h-9 w-9 rounded-full bg-gray-300 flex items-center justify-center text-gray-700 font-bold">
                      A
                    </div>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Admin Kullanıcı</p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700 flex items-center">
                      <LogOut className="mr-1 h-4 w-4" /> Çıkış Yap
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Menüyü Aç</span>
            <Menu className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
              <div className="w-full flex md:ml-0">
                <label htmlFor="search-field" className="sr-only">Ara</label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-3">
                    <Search className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className="block w-full h-full pl-10 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Tekliflerde ara..."
                    type="search"
                    name="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <span className="sr-only">Bildirimleri Göster</span>
                <Bell className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold text-gray-900">Sigorta Teklifleri</h1>
                
                {/* Otomatik Yenileme Kontrolü */}
                <div className="flex items-center space-x-2">
                  <div className="flex items-center">
                    <input
                      id="auto-refresh"
                      name="auto-refresh"
                      type="checkbox"
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      checked={autoRefresh}
                      onChange={(e) => setAutoRefresh(e.target.checked)}
                    />
                    <label htmlFor="auto-refresh" className="ml-2 text-sm text-gray-700">
                      Otomatik Yenile
                    </label>
                  </div>
                  <div className="flex items-center ml-4">
                    <select 
                      value={refreshInterval}
                      onChange={(e) => setRefreshInterval(parseInt(e.target.value))}
                      className="text-sm border-gray-300 rounded-md shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    >
                      <option value="10">10 sn</option>
                      <option value="30">30 sn</option>
                      <option value="60">1 dk</option>
                      <option value="300">5 dk</option>
                    </select>
                  </div>
                  <div className="text-xs text-gray-500">
                    Son Yenileme: {formattedLastRefreshTime}
                  </div>
                </div>
              </div>
            </div>
            
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              {/* İstatistik Kartları */}
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-3 mt-6">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">Toplam Teklif</dt>
                      <dd className="mt-1 text-3xl font-semibold text-gray-900">{stats.total}</dd>
                    </dl>
                  </div>
                </div>
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">İşlenmemiş Teklifler</dt>
                      <dd className="mt-1 text-3xl font-semibold text-yellow-500">{stats.pending}</dd>
                    </dl>
                  </div>
                </div>
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">İşlenmiş Teklifler</dt>
                      <dd className="mt-1 text-3xl font-semibold text-green-500">{stats.processed}</dd>
                    </dl>
                  </div>
                </div>
              </div>
              
              {/* Hata Mesajı */}
              {error && (
                <div className="rounded-md bg-red-50 p-4 mt-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <AlertTriangle className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">{error}</h3>
                    </div>
                  </div>
                </div>
              )}
              
              {/* Filtreler ve İşlem Butonları */}
              <div className="bg-white shadow rounded-lg mt-6 px-4 py-4 sm:flex sm:items-center sm:justify-between">
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500">Filtrele:</span>
                  <div className="flex rounded-md shadow-sm">
                    <button
                      type="button"
                      className={`px-4 py-2 text-sm font-medium rounded-l-md ${activeFilter === 'all' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'}`}
                      onClick={() => setActiveFilter('all')}
                    >
                      Tümü
                    </button>
                    <button
                      type="button"
                      className={`px-4 py-2 text-sm font-medium ${activeFilter === 'pending' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50 border-t border-b border-gray-300'}`}
                      onClick={() => setActiveFilter('pending')}
                    >
                      İşlenmemiş
                    </button>
                    <button
                      type="button"
                      className={`px-4 py-2 text-sm font-medium rounded-r-md ${activeFilter === 'processed' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'}`}
                      onClick={() => setActiveFilter('processed')}
                    >
                      İşlenmiş
                    </button>
                  </div>
                </div>
                <div className="mt-3 sm:mt-0">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => fetchOffers(currentPage, true)}
                  >
                    <RefreshCw className="mr-2 h-4 w-4" aria-hidden="true" />
                    Yenile
                  </button>
                </div>
              </div>
              
              {/* Teklif Tablosu */}
              <div className="mt-6 bg-white shadow overflow-hidden rounded-lg">
                {loading && (
                  <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                  </div>
                )}
                
                {filteredOffers.length === 0 ? (
                  <div className="px-4 py-8 text-center text-gray-500">
                    {offers.length === 0 ? 'Henüz teklif bulunmamaktadır.' : 'Filtreye uygun teklif bulunamadı.'}
                  </div>
                ) : (
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Teklif Tipi
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            TC Kimlik
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Telefon
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Plaka/Doğum Tarihi
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Ruhsat No/Aile Poliçesi
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Durum
                          </th>
                          <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            İşlem
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {filteredOffers.map((offer) => {
                          const isProcessed = offer.status === 'processed';
                          const { plakaOrDate, ruhsatOrAile, displayType } = getOfferTypeSpecificData(offer);
                          return (
                            <tr key={`${offer.type}-${offer.id}`} className="hover:bg-gray-50">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {displayType}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {offer.tc_kimlik}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {offer.telefon}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {plakaOrDate}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {ruhsatOrAile}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${isProcessed ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                                  {isProcessed ? 'İşlendi' : 'İşlenmedi'}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button
                                  onClick={() => handleStatusChange(
                                    offer.id, 
                                    isProcessed ? 'pending' : 'processed', 
                                    offer.type
                                  )}
                                  className={`inline-flex items-center px-3 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${isProcessed ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-green-500 hover:bg-green-600'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                                >
                                  {isProcessed ? 'İşlenmedi Yap' : 'İşlendi Yap'}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                
                {/* Sayfalama */}
                {totalPages > 1 && (
                  <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Toplam <span className="font-medium">{offers.length}</span> tekliften <span className="font-medium">{(currentPage - 1) * 10 + 1}</span> - <span className="font-medium">{Math.min(currentPage * 10, offers.length)}</span> arası gösteriliyor
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Önceki
                      </button>
                      <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Sonraki
                      </button>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminPanel;