import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SigortaAcentesiAnasayfa from './SigortaAcentesiAnasayfa';
import UrunlerSayfasi from './UrunlerSayfasi';
import HakkimizdaSayfasi from './HakkimizdaSayfasi';
import IletisimSayfasi from './IletisimSayfasi';
import TrafikSigortasiFiyat from './TrafikSigortasiFiyat';
import KaskoFiyat from './KaskoFiyat';
import TamamlayiciSaglik from './TamamlayiciSaglik';
import AdminLogin from './AdminLogin';
import AdminPanel from './AdminPanel';
import SiteKullanim from './SiteKullanim';
import DaskForm from './DaskForm';  // veya './components/DaskForm' gibi doğru yolu belirtin


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/check-auth.php')
      .then(response => response.json())
      .then(data => {
        setIsAuthenticated(data.authenticated);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Auth check failed:', error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/admin-login" replace />;
    }
    return children;
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SigortaAcentesiAnasayfa />} />
          <Route path="/kasko-fiyat" element={<KaskoFiyat />} />
          <Route path="/urunler" element={<UrunlerSayfasi />} />
          <Route path="/hakkimizda" element={<HakkimizdaSayfasi />} />
          <Route path="/iletisim" element={<IletisimSayfasi />} />
          <Route path="/insurance/car" element={<TrafikSigortasiFiyat />} />
          <Route path="/tamamlayici-saglik-fiyat" element={<TamamlayiciSaglik />} />
          <Route path="/dask" element={<DaskForm />} />
          <Route 
            path="/admin-login" 
            element={isAuthenticated ? <Navigate to="/admin-panel" replace /> : <AdminLogin />} 
          />
          <Route 
            path="/admin-panel" 
            element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            } 
          />
          <Route path="/kullanim-kosullari" element={<SiteKullanim />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;