import React from 'react';
import { Users, Award, ThumbsUp } from 'lucide-react';

const teamMembers = [
  { name: 'Ahmet Yılmaz', role: 'Genel Müdür' },
  { name: 'Ayşe Kaya', role: 'Satış Müdürü' },
  { name: 'Mehmet Demir', role: 'Müşteri İlişkileri Yöneticisi' },
];

export default function HakkimizdaSayfasi() {
  return (
    <div className="bg-white min-h-screen">
      <header className="bg-red-600 text-white p-6">
        <h1 className="text-3xl font-bold">Hakkımızda</h1>
      </header>
      <main className="container mx-auto py-12 px-4">
      <section className="mb-12">
  <h2 className="text-2xl font-semibold mb-4">Biz Kimiz?</h2>
  <p className="text-gray-700 mb-4">
    15 yıldır sigorta sektöründe faaliyet gösteren şirketimiz, müşterilerimize en
    iyi hizmeti sunma misyonuyla çalışmaktadır. Deneyimli ekibimiz ve geniş ürün
    yelpazemizle, sizin için en uygun sigorta çözümlerini sunuyoruz.
  </p>
  <p className="text-gray-700 mb-4">
    <strong>Centalya Sigorta ARC. HİZ. LTD. ŞTİ Şirket Bilgileri</strong><br />
    <strong>Ünvanı:</strong> Centalya Sigorta Aracılık Hizmetleri <br />
    <strong>Yetkili Kişi:</strong> Fatma Çakır<br />
    <strong>TOBB'a Kayıtlı Levha No:</strong> T14114-G6KP<br />
    <strong>Adresimiz:</strong> Kanal Mah. Yasemin Cad. No:7/B Kepez / ANTALYA<br />
    <strong>Telefon:</strong> 0242 331 03 00 - 0501 076 07 07
  </p>
</section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Değerlerimiz</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { icon: Users, title: 'Müşteri Odaklılık', desc: 'Her zaman müşterilerimizin ihtiyaçlarını ön planda tutuyoruz.' },
              { icon: Award, title: 'Uzmanlık', desc: 'Sektördeki deneyimimizle en iyi hizmeti sunuyoruz.' },
              { icon: ThumbsUp, title: 'Güvenilirlik', desc: 'Dürüstlük ve şeffaflık ilkelerimizden asla taviz vermiyoruz.' },
            ].map((item, index) => (
              <div key={index} className="text-center">
                <item.icon className="w-12 h-12 mx-auto text-red-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.desc}</p>
              </div>
            ))}
          </div>
        </section>

      </main>
    </div>
  );
}