import React, { useEffect, useState, useCallback } from 'react';

const AdminPanel = () => {
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchOffers = useCallback(async (page) => {
    setLoading(true);
    try {
      // URL'de giriş dizinini (/) eklediğimizden emin olalım
      const response = await fetch(`/get-all-offers.php?page=${page}`);
      const data = await response.json();
      
      if (data.success) {
        console.log("API Response:", data); // Detaylı loglama
        setOffers(data.data);
        setTotalPages(Math.ceil(data.totalCount / data.perPage));
        setError(null);
      } else {
        console.error('API error:', data.error);
        setError(data.error || 'Teklifler alınırken bir hata oluştu.');
        setOffers([]);
      }
    } catch (error) {
      console.error('Teklifler alınırken hata oluştu:', error);
      setError('Teklifler alınırken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      setOffers([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOffers(currentPage);
  }, [currentPage, fetchOffers]);

  const handleStatusChange = async (id, newStatus, offerType) => {
    // Debug için loglama ekliyoruz
    console.log(`Durum güncellemesi başlatılıyor: ID=${id}, Yeni Durum=${newStatus}, Tip=${offerType}`);
    
    try {
      const payload = { 
        id: id.toString(), // String olarak göndermek daha güvenli olabilir
        status: newStatus, 
        type: offerType 
      };
      
      console.log("Gönderilen veri:", payload);
      
      const response = await fetch('/update-offer-status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      
      const result = await response.json();
      console.log("API yanıtı:", result);
      
      if (result.success) {
        // Başarılı olursa tüm listeyi yenilemek yerine
        // sadece ilgili kaydı güncelle (optimistik güncelleme)
        setOffers(prevOffers => 
          prevOffers.map(offer => 
            (offer.id === id && offer.type === offerType) 
              ? { ...offer, status: newStatus } 
              : offer
          )
        );
        setError(null);
      } else {
        console.error('Durum güncellenirken hata oluştu:', result.message);
        setError(`Durum güncellenirken hata oluştu: ${result.message}`);
      }
    } catch (error) {
      console.error('Durum güncellenirken hata oluştu:', error);
      setError(`Durum güncellenirken hata oluştu: ${error.message}`);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`mx-1 px-3 py-1 border rounded ${currentPage === i ? 'bg-blue-500 text-white' : 'bg-white'}`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  const getOfferTypeSpecificData = (offer) => {
    switch (offer.type) {
      case 'trafik':
        return {
          plakaOrDate: offer.plaka,
          ruhsatOrAile: offer.ruhsat_no 
        };
      case 'saglik':
        return {
          plakaOrDate: offer.plaka, // dogum_tarihi olarak gelen değer
          ruhsatOrAile: offer.ruhsat_no === '1' ? 'Evet' : 'Hayır' // aile_police olarak gelen değer
        };
      case 'kasko':
        return {
          plakaOrDate: offer.plaka,
          ruhsatOrAile: offer.ruhsat_no
        };
      default:
        return {
          plakaOrDate: '-',
          ruhsatOrAile: '-'
        };
    }
  };

  if (loading && offers.length === 0) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Admin Paneli</h1>
        <div className="flex justify-center items-center h-64">
          <div className="text-lg">Teklifler yükleniyor...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Paneli</h1>
      
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">{error}</div>}
      
      <div className="mb-4 flex justify-between items-center">
        <div>
          <span className="font-medium">Toplam Teklif: {offers.length}</span>
        </div>
        <button 
          onClick={() => fetchOffers(currentPage)} 
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
        >
          Yenile
        </button>
      </div>
      
      {offers.length === 0 ? (
        <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded">
          Gösterilecek teklif bulunamadı.
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse border">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border p-2">Teklif Tipi</th>
                  <th className="border p-2">TC Kimlik</th>
                  <th className="border p-2">Telefon</th>
                  <th className="border p-2">Plaka/Doğum Tarihi</th>
                  <th className="border p-2">Ruhsat No/Aile Poliçesi</th>
                  <th className="border p-2">Durum</th>
                  <th className="border p-2">İşlem</th>
                </tr>
              </thead>
              <tbody>
                {offers.map((offer) => {
                  const isProcessed = offer.status === 'processed';
                  const { plakaOrDate, ruhsatOrAile } = getOfferTypeSpecificData(offer);
                  
                  // Benzersiz bir anahtar oluştur (sadece id yeterli olmayabilir)
                  const uniqueKey = `${offer.type}-${offer.id}`;
                  
                  return (
                    <tr 
                      key={uniqueKey} 
                      className={`${isProcessed ? 'bg-green-100' : 'bg-yellow-100'}`}
                    >
                      <td className="border p-2">{offer.type}</td>
                      <td className="border p-2">{offer.tc_kimlik}</td>
                      <td className="border p-2">{offer.telefon}</td>
                      <td className="border p-2">{plakaOrDate}</td>
                      <td className="border p-2">{ruhsatOrAile}</td>
                      <td className="border p-2">{isProcessed ? 'İşlendi' : 'İşlenmedi'}</td>
                      <td className="border p-2">
                        <button
                          onClick={() => handleStatusChange(
                            offer.id, 
                            isProcessed ? 'pending' : 'processed', 
                            offer.type
                          )}
                          className={`p-2 rounded ${isProcessed ? 'bg-yellow-500' : 'bg-green-500'} text-white`}
                        >
                          {isProcessed ? 'İşlenmedi Yap' : 'İşlendi Yap'}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          
          <div className="mt-4 flex justify-center">
            {totalPages > 1 && renderPagination()}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminPanel;