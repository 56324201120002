import React from 'react';
import { Shield } from 'lucide-react';
import { Link } from 'react-router-dom';

const products = [
  { 
    name: 'Trafik Sigortası', 
    description: 'Zorunlu trafik sigortası ile güvende kalın.',
    link: '/insurance/car',
    active: true
  },
  { 
    name: 'Kasko', 
    description: 'Aracınızı her türlü riske karşı koruyun.',
    link: '/kasko-fiyat',
    active: true
  },
  { 
    name: 'Sağlık Sigortası', 
    description: 'Sağlığınız için en iyi korumayı sağlayın.',
    link: '/tamamlayici-saglik-fiyat',
    active: true
  },
  { 
    name: 'Konut Sigortası', 
    description: 'Evinizi ve eşyalarınızı güvence altına alın.',
    active: false
  },
  { 
    name: 'Seyahat Sigortası', 
    description: 'Tatilinizi güvenle geçirin.',
    active: false
  },
  { 
    name: 'İşyeri Sigortası', 
    description: 'İşletmenizi risklere karşı koruyun.',
    active: false
  },
];

export default function UrunlerSayfasi() {
  return (
    <div className="bg-white min-h-screen">
      <header className="bg-red-600 text-white p-6">
        <h1 className="text-3xl font-bold">Ürünlerimiz</h1>
      </header>
      <main className="container mx-auto py-12 px-4">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <div key={index} className={`bg-gray-100 rounded-lg p-6 shadow-md hover:shadow-lg transition duration-300 ${!product.active ? 'opacity-50' : ''}`}>
              <Shield className="w-12 h-12 text-red-600 mb-4" />
              <h2 className="text-xl font-semibold mb-2">{product.name}</h2>
              <p className="text-gray-600">{product.description}</p>
              {product.active ? (
                <Link to={product.link} className="mt-4 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300 inline-block">
                  Fiyat Al
                </Link>
              ) : (
                <div className="mt-4 text-red-600 font-bold">Yakında!</div>
              )}
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}